.main {
    width: 300px;
    height: 400px;
    background-color: white;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}

.image {
    flex: 2;
}

.disc {
    flex: 1;
    background-color: rgb(44, 49, 65);
    padding: 1rem;
    color: gray;
    /* text-align: center; */
}

.disc h2 {
    color: white;
    margin-bottom: 0.5rem;
}

@media (max-width: 1600px) {
    .main {
        margin: 2rem auto;
    }
}
