.settings {
    background-color: var(--main-color);
    padding-top: 4rem;
}

.line {
    margin-left: 10rem;
    margin-right: 10rem;
}

.main {
    /* height: 100vh; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    color: white;
    width: 65vw;
    margin: auto;
    /* margin-top: 10rem; */
}

.left {
    flex: 1;
    /* background-color: rgb(56, 33, 33); */
    padding-top: 2rem;
    margin-right: 1rem;
}

.right {
    flex: 1;
    /* background-color: rgb(36, 68, 68); */
    padding-top: 2rem;
}

.show-more-less {
    font-size: medium;
    font-weight: 400;
    margin-top: 3rem;
    display: block;
    margin-bottom: 2rem;
}

@media (max-width: 415px) {
    .main {
        display: flex;
        flex-direction: column;
    }
}
