.main {
    margin-top: 2rem;
    width: 100%;
    height: 6rem;
    border-bottom: 1px solid rgb(75, 81, 105);
    display: flex;
    flex-direction: row;
}
.main:hover {
    border-bottom: 1px solid var(--text-color);
}
.pic {
    background-color: aliceblue;
    flex: 1;
    margin: 0.5rem;
    border-radius: 5px;
}

.info {
    flex: 3;
    padding-top: 1rem;
    text-align: left;
    padding-left: 1rem;
    cursor: pointer;
}

.time {
    flex: 1;
    color: gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.icons {
    flex: 1;
    display: flex;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.music-title {
    color: white;
    font-size: medium;
}

.artist {
    color: gray;
}

.share {
    color: white;
}
.more {
    font-size: 3rem;
    color: var(--text-color);

    padding-bottom: 1.8rem;
    margin-left: 1rem;
 
}
.number {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    color: gray;
}
