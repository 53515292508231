.nav {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.nav ul {
    display: flex;
    list-style: none;
    padding: 0;
    background-color: rgb(29, 30, 50);
    margin: 0;
}
.insted-nav {
    display: none;
}
.item {
    overflow: hidden;
    padding: 1.2rem 2rem;
    color: white;
    cursor: pointer;
}

.item:first-child {
    margin-left: 10rem;
}

.item:hover {
    border-bottom: 6px rgb(239, 36, 95) solid;
    padding-bottom: 0.5rem;
    transition: all 200ms;
}

@media (max-width: 415px) {
    .nav ul li {
        display: none;
    }
    .nav {
        background-color: var(--main-color);
        height: 10vh;
        display: flex;
    }
    .insted-nav {
        display: block;
        font-size: 3rem;
    }
}
