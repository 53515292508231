body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

* {
    margin: 0;
    padding: 0;
}

:root {
    --main-color: rgb(29, 30, 50);
    --text-color: rgb(239, 36, 95);
}

.Navbar_nav__3DM4X {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.Navbar_nav__3DM4X ul {
    display: flex;
    list-style: none;
    padding: 0;
    background-color: rgb(29, 30, 50);
    margin: 0;
}
.Navbar_insted-nav__3U61h {
    display: none;
}
.Navbar_item__299FJ {
    overflow: hidden;
    padding: 1.2rem 2rem;
    color: white;
    cursor: pointer;
}

.Navbar_item__299FJ:first-child {
    margin-left: 10rem;
}

.Navbar_item__299FJ:hover {
    border-bottom: 6px rgb(239, 36, 95) solid;
    padding-bottom: 0.5rem;
    transition: all 200ms;
}

@media (max-width: 415px) {
    .Navbar_nav__3DM4X ul li {
        display: none;
    }
    .Navbar_nav__3DM4X {
        background-color: var(--main-color);
        height: 10vh;
        display: flex;
    }
    .Navbar_insted-nav__3U61h {
        display: block;
        font-size: 3rem;
    }
}

.top_main-top__1gXKE {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 92.7vh; */
    background: linear-gradient(rgb(29, 30, 50, 0.3), rgb(29, 30, 50)),
        url(/static/media/got.a1446bff.jpg) no-repeat center center/ cover;
    color: white;
    font-size: 1.2rem;
}
h1 {
    margin-top: 20rem;
}

.top_btn__1mqZW {
    color: white;
    background-color: rgb(239, 36, 95);
    padding: 0.4rem 2rem;
    border-radius: 20px;
    cursor: pointer;
}

.top_btn__1mqZW:hover {
    background-color: rgba(218, 26, 80, 0.76);
}
.top_next__ofInm {
    margin-top: 2rem;
    display: flex;
}

.top_share__Sk7Sz {
    margin-left: 1.5rem;
    margin-top: 0.4rem;
    display: flex;
    cursor: pointer;
}

.top_share-logo__1IZsY {
    stroke: white;
    width: 2rem;
    margin-right: 1rem;
}
i {
    color: rgba(218, 26, 80, 0.76);
    margin-left: 1rem;
}
span {
    font-size: 1.5rem;
    margin-left: 0.5rem;
}

.top_comment__2tlN0 {
    display: flex;
    margin-top: 0.4rem;
    cursor: pointer;
}

.top_photos__tVi9Z {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    margin-top: 5rem;
}

.top_this-shit__2uIeM {
    display: flex;
    flex-direction: column;
}

@media (max-width: 845px) {
    .top_photos__tVi9Z {
        display: none;
    }

    .top_main-top__1gXKE h1 {
        font-size: 1.5rem;
    }
    .top_main-top__1gXKE span,
    .top_main-top__1gXKE i {
        display: none;
    }
    .top_btn__1mqZW {
        display: block;
        margin: auto;
        margin-left: 2.5rem;
    }

    h1 {
        margin-top: 16rem;
    }
}

.topphoto_main__1Yu46 {
    width: 400px;
    height: 30vh;
    margin: 1rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    font-size: 5rem;
    font-weight: bolder;
    cursor: pointer;
}

.middle_settings__2qPLO {
    background-color: var(--main-color);
    padding-top: 4rem;
}

.middle_line__2TPAU {
    margin-left: 10rem;
    margin-right: 10rem;
}

.middle_main__1oQ_D {
    /* height: 100vh; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    color: white;
    width: 65vw;
    margin: auto;
    /* margin-top: 10rem; */
}

.middle_left__WW6PE {
    flex: 1 1;
    /* background-color: rgb(56, 33, 33); */
    padding-top: 2rem;
    margin-right: 1rem;
}

.middle_right__1Pj_k {
    flex: 1 1;
    /* background-color: rgb(36, 68, 68); */
    padding-top: 2rem;
}

.middle_show-more-less__1B4rY {
    font-size: medium;
    font-weight: 400;
    margin-top: 3rem;
    display: block;
    margin-bottom: 2rem;
}

@media (max-width: 415px) {
    .middle_main__1oQ_D {
        display: flex;
        flex-direction: column;
    }
}

.musicbar_main__2w0AD {
    margin-top: 2rem;
    width: 100%;
    height: 6rem;
    border-bottom: 1px solid rgb(75, 81, 105);
    display: flex;
    flex-direction: row;
}
.musicbar_main__2w0AD:hover {
    border-bottom: 1px solid var(--text-color);
}
.musicbar_pic__2_MgM {
    background-color: aliceblue;
    flex: 1 1;
    margin: 0.5rem;
    border-radius: 5px;
}

.musicbar_info__19y6Q {
    flex: 3 1;
    padding-top: 1rem;
    text-align: left;
    padding-left: 1rem;
    cursor: pointer;
}

.musicbar_time__Lbb3V {
    flex: 1 1;
    color: gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.musicbar_icons__2DANY {
    flex: 1 1;
    display: flex;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.musicbar_music-title__2E1S- {
    color: white;
    font-size: medium;
}

.musicbar_artist__1u1vK {
    color: gray;
}

.musicbar_share__3xDCd {
    color: white;
}
.musicbar_more__35Xlt {
    font-size: 3rem;
    color: var(--text-color);

    padding-bottom: 1.8rem;
    margin-left: 1rem;
 
}
.musicbar_number__19bTi {
    flex: 1 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    color: gray;
}

.footer_main__19Qu8 {
    display: flex;
    background-color: rgb(31, 35, 59);
}
.footer_def__nVQiF {
    width: 100%;
    height: 100%;
    margin: 3rem 19rem;
    /* background-color: aliceblue; */
}
.footer_header__3Ah3E {
    color: white;
}

.footer_middle__2fEF5 {
    margin: 1rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 1600px) {
    .footer_middle__2fEF5 {
        grid-template-columns: repeat(2, 1fr);
    }
    .footer_def__nVQiF {
        margin: 0;
    }
}

@media (max-width: 415px) {
    .footer_middle__2fEF5 {
        display: flex;
        flex-direction: column;
        margin: 0;
    }
    .footer_main__19Qu8 {
        margin: 0;
    }
    .footer_header__3Ah3E {
        margin-top: 2rem;
        text-align: center;
    }

    .footer_def__nVQiF {
        margin: 0;
    }
}

.moods_main__3jc78 {
    width: 300px;
    height: 400px;
    background-color: white;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}

.moods_image__1VwoQ {
    flex: 2 1;
}

.moods_disc__1RWgl {
    flex: 1 1;
    background-color: rgb(44, 49, 65);
    padding: 1rem;
    color: gray;
    /* text-align: center; */
}

.moods_disc__1RWgl h2 {
    color: white;
    margin-bottom: 0.5rem;
}

@media (max-width: 1600px) {
    .moods_main__3jc78 {
        margin: 2rem auto;
    }
}

