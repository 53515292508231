.main {
    width: 400px;
    height: 30vh;
    margin: 1rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    font-size: 5rem;
    font-weight: bolder;
    cursor: pointer;
}
