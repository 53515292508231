.main {
    display: flex;
    background-color: rgb(31, 35, 59);
}
.def {
    width: 100%;
    height: 100%;
    margin: 3rem 19rem;
    /* background-color: aliceblue; */
}
.header {
    color: white;
}

.middle {
    margin: 1rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 1600px) {
    .middle {
        grid-template-columns: repeat(2, 1fr);
    }
    .def {
        margin: 0;
    }
}

@media (max-width: 415px) {
    .middle {
        display: flex;
        flex-direction: column;
        margin: 0;
    }
    .main {
        margin: 0;
    }
    .header {
        margin-top: 2rem;
        text-align: center;
    }

    .def {
        margin: 0;
    }
}
