.main-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 92.7vh; */
    background: linear-gradient(rgb(29, 30, 50, 0.3), rgb(29, 30, 50)),
        url("./got.jpg") no-repeat center center/ cover;
    color: white;
    font-size: 1.2rem;
}
h1 {
    margin-top: 20rem;
}

.btn {
    color: white;
    background-color: rgb(239, 36, 95);
    padding: 0.4rem 2rem;
    border-radius: 20px;
    cursor: pointer;
}

.btn:hover {
    background-color: rgba(218, 26, 80, 0.76);
}
.next {
    margin-top: 2rem;
    display: flex;
}

.share {
    margin-left: 1.5rem;
    margin-top: 0.4rem;
    display: flex;
    cursor: pointer;
}

.share-logo {
    stroke: white;
    width: 2rem;
    margin-right: 1rem;
}
i {
    color: rgba(218, 26, 80, 0.76);
    margin-left: 1rem;
}
span {
    font-size: 1.5rem;
    margin-left: 0.5rem;
}

.comment {
    display: flex;
    margin-top: 0.4rem;
    cursor: pointer;
}

.photos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    margin-top: 5rem;
}

.this-shit {
    display: flex;
    flex-direction: column;
}

@media (max-width: 845px) {
    .photos {
        display: none;
    }

    .main-top h1 {
        font-size: 1.5rem;
    }
    .main-top span,
    .main-top i {
        display: none;
    }
    .btn {
        display: block;
        margin: auto;
        margin-left: 2.5rem;
    }

    h1 {
        margin-top: 16rem;
    }
}
